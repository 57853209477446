import { env } from './.env';

export const environment = {
  production: true,
  version: env['npm_package_version'],
  serverUrl: 'https://www.french-rendez-vous.com/api/v3',
  eplusSystem: 'https://www.french-rendez-vous.com/eplus',
  defaultLanguage: 'en-EN',
  supportedLanguages: ['en-EN'],
  api_key: 'atout-frech-1234',
  day_1: '22.05.2024',
  day_2: '23.05.2024',
  linkTerms: 'https://www.french-rendez-vous.com/eplus/_fileDB/terms.pdf',
  linkDSGVO: '#',
  linkStorno: '#',
  event_year: '2024',
  hotel_info: 'the DoubleTree by Hilton Vienna Schönbrunn', // lunch at {the DoubleTree by Hilton Vienna Schönbrunn}
  event_space: 'Schönbrunn Palace', // evening at the {Schönbrunn Palace} on 22.05.24
  event_date: '22.05 2024', // evening at the Schönbrunn Palace on {22.05.24}
  book_night: '22.05/23.05 2024', // book a room in my name for the night of {22.05/23.05 2024} at the
  cancel_date: 'May 1st 2024', // for all cancellations after {May 10th 2023}, the
  event_dates: '22nd May and 23rd May 2024', // {31st May and 1st June 2023} in Vienna - Austria
};

import { Component, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, HostListener, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SlideInOutAnimation } from '../animations';
import { ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { tap, map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as tus from 'tus-js-client';

import { environment } from '../../environments/environment';
import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, MailService, Mail } from '@core';
import { CookieService } from 'ngx-cookie-service';

import { AuthDataService } from '@app/auth/auth-data.service';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material/progress-spinner';

import { requireAtLeastOneCheckboxValidator } from '../@shared/validation/require-checkboxes-to-be-checked.validator';

import { ExhibitorService, Exhibitor, ExhibitorTable, ExhibitorSingle } from '../services/exhibitor.service';

const log = new Logger('Login');

enum myTabs {
  tabWwelcome = 0,
  tabLogin = 1,
  tabRegisterNew = 2,
  tabRegisterSuccess = 5,
  tabForgotPawd = 3,
  tabFirststart = 4,
}

@Component({
  selector: 'app-phuture-login',
  templateUrl: './phuture-login.component.html',
  styleUrls: ['./phuture-login.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          opacity: '1',
          'z-index': '100',
        })
      ),
      state(
        'out',
        style({
          opacity: '0',
          'z-index': '100',
        })
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out')),
    ]),
  ],
})
export class PhutureLoginComponent implements OnInit {
  // environment
  _eventYear = environment.event_year;
  _hotel_info = environment.hotel_info;
  _event_space = environment.event_space;
  _event_date = environment.event_date;
  _book_night = environment.book_night;
  _cancel_date = environment.cancel_date;
  // ---
  myVideo: any = null;
  videoContainer: any = null;

  dialogIsShown: boolean = false;
  isToggle: boolean = false;
  imageName: string = '';
  videoTag: any = null;

  playing: boolean = false;
  playingIcon: string = 'pause';
  mute: boolean = true;
  muteIcon: string = 'volume_mute';
  eye: boolean = false;
  eyeIcon: string = 'visibility_off';

  activeTab: number = myTabs.tabWwelcome;

  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  registerForm!: FormGroup;
  forgotForm!: FormGroup;
  isLoading = false;

  // firststart
  colLarge = 5;
  colXLarge = 5;
  showWelcome: boolean = true;
  myGroup: string = '';

  firstExhibitorForm: FormGroup;
  submitted = false;

  // exhibitor firststart data
  imageStorage = environment.eplusSystem + '/_fileDB/images/exhibitor/';
  _profile_image = '';
  _logo = '';
  _bild1 = '';
  _bild2 = '';
  _bild3 = '';

  isLoginClicked = false;
  isRegisterNewClicked = false;
  isForgetClicked = false;

  @ViewChild('myPond') myPond: any;

  feedback: any;

  pondOptions = {
    class: 'my-filepond',
    credits: false,
    multiple: false,
    allowFileTypeValidation: true,
    acceptedFileTypes: ['image/png', 'image/jpeg'],
    labelFileTypeNotAllowed: 'File of invalid type: only JPG, PNG',
    fileValidateTypeLabelExpectedTypesMap: { 'image/jpeg': '.jpg', 'image/png': '.png' },
    allowImageValidateSize: true,
    imageValidateSizeMinWidth: 150,
    imageValidateSizeMinHeight: 150,
    allowImageResize: true,
    imageResizeTargetWidth: 2400,
    imageResizeTargetHeight: 1600,
    imageResizeMode: 'contain',
    imageResizeUpscale: true,
    imageCropAspectRatio: 1,
    labelIdle: 'déposer le fichier ici ou <a style="text-decoration:underline;">parcourir</a> ...',
    // acceptedFileTypes: ['image/jpeg, image/png'],
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        //debugger;
        //console.table(fieldName);
        const formData = new FormData();
        formData.append(fieldName, file, file.name);
        formData.append('filepond', file.name);

        formData.append('uid', '' + this.authQuery.userid);
        formData.append('fid', '' + this.authQuery.fid);
        formData.append('role', '' + this.authQuery.role);
        formData.append('imgtype', '' + fieldName);

        const request = new XMLHttpRequest();
        request.open('POST', environment.serverUrl + '/filepond/process');

        // set `Content-Type` header
        //request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Access-Control-Allow-Origin', '*');
        //xhr.setRequestHeader('Accept', "application/json");
        request.setRequestHeader('Access-Control-Allow-Headers', '*');

        // token
        request.setRequestHeader('Authorization', `Bearer ${this.authQuery.access_token}`);

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
      revert: 'filepond/revert',
      restore: 'filepond/restore?id=',
      fetch: 'filepond/fetch?data=',
      load: 'filepond/load',
    },
  };

  pondFiles = [
    // 'index.html'
  ];

  /*
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        let that = this;

        that.feedback = 'Processing..';

        console.log(file);

        var upload = new tus.Upload(file, {
          endpoint: environment.serverUrl + '/filepond/process',
          retryDelays: [0, 1000, 3000, 5000],
          metadata: {
            ...metadata,
            filename: file.name,
            filetype: file.type,
            freddy: 'mercury',
            file: file,
          },

          async onBeforeRequest(req) {
            const token = await that.authQuery.access_token;
            req.setHeader('Authorization', `Bearer ${token}`);
          },

          onError: function (err) {
            that.feedback = 'Failed because: ' + err;
            console.log('Failed because: ' + err);
            error(err);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            that.feedback = 'Upload progress';
            progress(true, bytesUploaded, bytesTotal);

            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            console.log(bytesUploaded, bytesTotal, percentage + '%');
          },
          onChunkComplete: (chunkSize: number, bytesAccepted: number) => {},
          onSuccess: function () {
            console.log('Download from %s complete', upload.url);
            (that.feedback = 'Download from %s complete'), upload.url;
            // blob is now outside of zone
            that.feedback = upload.url;
            console.log(that.feedback);
            //this.cdr.detectChanges();
            //load(upload.url.split('/').pop());
          },
        });
        // Start the upload
        //upload.options.onSuccess = () => this.pondHandleSuccess(this);
        upload.start();

        return {
          abort: () => {
            upload.abort();
            abort();
          },
        };
      },
      */

  // acitivities --> exhibitor
  activities_en: any = [];
  activities_fr: any = [];

  // FORM config
  showTerms = true;
  showDSGVO = false;
  showStorno = false;
  linkTerms = environment.linkTerms;
  linkDSGVO = environment.linkDSGVO;
  linkStorno = environment.linkStorno;

  showDays = false; // show the day selection info
  _day1 = environment.day_1;
  _day2 = environment.day_2;
  //_hotel_info = environment.hotel_info;

  // firststart DATA
  fsData: any;
  fsShowFiles = false;

  isMobile = false;
  isTablet = false;
  isDesktop = false;

  constructor(
    private element: ElementRef,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthDataService,
    private authQuery: AuthQuery,
    private router: Router,
    private route: ActivatedRoute,
    private journalService: JournalService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private exhibitorService: ExhibitorService
  ) {
    // GET THE DEVICE INFO
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();

    // INIT THE FORMS
    // create login form
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    // create forgot form
    this.forgotForm = this.formBuilder.group({
      email: ['', Validators.required],
    });

    // create register form
    this.registerForm = this.formBuilder.group({
      company: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      position: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      role: ['exhibitor', Validators.required],
    });

    ////// -------------------------- firststart
    this.activities_en[0] = 'Accomodation';
    this.activities_en[1] = 'Barging';
    this.activities_en[2] = 'Carriers';
    this.activities_en[3] = 'Cultural Sites';
    this.activities_en[4] = 'Excursions / Entertainment';
    this.activities_en[5] = 'Incoming services';
    this.activities_en[6] = 'Leisure or entertainment park';
    this.activities_en[7] = 'Restaurant';
    this.activities_en[8] = 'Ski';
    this.activities_en[9] = 'Tourist office';
    this.activities_en[10] = 'Other';
    this.activities_en[11] = 'Shopping';
    this.activities_en[12] = 'Luxury offers';
    this.activities_en[13] = 'MICE Services';

    this.activities_fr[0] = 'Hébergement';
    this.activities_fr[1] = 'Location fluviale';
    this.activities_fr[2] = 'Transporteur';
    this.activities_fr[3] = 'Sites culturels';
    this.activities_fr[4] = 'Excursion, guide';
    this.activities_fr[5] = 'Agence réceptive';
    this.activities_fr[6] = "Parc d'attraction ou de loisirs";
    this.activities_fr[7] = 'Restaurant ';
    this.activities_fr[8] = 'Ski';
    this.activities_fr[9] = 'Office du Tourisme';
    this.activities_fr[10] = 'Autre';
    this.activities_fr[11] = 'Shopping';
    this.activities_fr[12] = 'Luxury offers';
    this.activities_fr[13] = 'MICE Services';

    // load and init the form
    this.firstExhibitorForm = this.formBuilder.group({
      uid: this.authQuery.userid,
      fid: this.authQuery.fid,
      role: this.authQuery.role,
      firststart_done: this.authQuery.firststart_done,

      //storno_accepted: [true, Validators.requiredTrue],
      //dsgvo_accepted: [true, Validators.requiredTrue],
      agb_accepted: [false, Validators.requiredTrue],

      taxid_uid: ['', Validators.required],
      special_wishes: [''],

      //profile_image: [''], // via filepond solved

      //organisation: ['', Validators.required],
      //name: ['', Validators.required],
      //vorname: ['', Validators.required],
      //funktion: ['', Validators.required],
      adresse: [this.fsData?.adresse, Validators.required],
      plz: [this.fsData?.plz, Validators.required],
      stadt: [this.fsData?.stadt, Validators.required],
      telefon: [this.fsData?.telefon, Validators.required],
      //fax: [this.fsData?.fax, Validators.required],
      email: [this.fsData?.email, [Validators.required, Validators.email]],
      web: [this.fsData?.web, Validators.required],

      category: ['', Validators.required],

      beschreibung: ['', Validators.required],
      info1: ['', Validators.required], // neuigkeiten

      // region
      regionsGroup: new FormGroup(
        {
          auvergne: new FormControl(false),
          bourgogne: new FormControl(false),
          bretagne: new FormControl(false),
          centreloire: new FormControl(false),
          corse: new FormControl(false),
          champagne: new FormControl(false),
          nordcalais: new FormControl(false),
          normandie: new FormControl(false),
          aquitaine: new FormControl(false),
          languedoc: new FormControl(false),
          outremer: new FormControl(false),
          paris: new FormControl(false),
          paysloire: new FormControl(false),
          picardie: new FormControl(false),
          paca: new FormControl(false),
          multiregions: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      //alsace: new FormControl(false),
      //limousin: new FormControl(false),
      //lorraine: new FormControl(false),
      //midipyrenes: new FormControl(false),
      //poitoucharentes: new FormControl(false),
      //rhonealpes: new FormControl(false),
      //riveria: new FormControl(false),
      //monaco: new FormControl(false),
      //thaiti: new FormControl(false),
      //luxury: new FormControl(false),
      //mice: new FormControl(false),

      // activite
      activitiesGroup: new FormGroup(
        {
          agencereceptive: new FormControl(false),
          centralereservation: new FormControl(false),
          destination: new FormControl(false),
          excursion: new FormControl(false),
          groupement: new FormControl(false),
          locationfluviale: new FormControl(false),
          musee: new FormControl(false),
          transporteur: new FormControl(false),
          autre: new FormControl(false),
          offreski: new FormControl(false),
          ski_station: new FormControl(false),
          ski_rental: new FormControl(false),
          residencetourisme: new FormControl(false),
          restaurant: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // bilder
      //bild1: ['', Validators.required],
      //bild2: ['', Validators.required],
      //bild3: ['', Validators.required],
      //logo: ['', Validators.required],

      // tage teilnahme
      chkcity1: ['true'],
      chkcity2: ['true'],
      //chkcity3: ['', Validators.required],
      //chkcity4: ['', Validators.required],
      //chkcity5: ['', Validators.required],

      //room: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    //console.log('init');

    // check if mobile device --> then show login form
    if (this.isMobile) {
      if (!this.dialogIsShown) {
        //this.toggleBackdrop('overlay');
        this.toggleShowForm('myForm');
        this.dialogIsShown = true;
      }
    }
  }

  ngOnChanges() {}

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  animationState = 'out';
  backdropState = 'out';

  toggleBackdrop(divName: string) {
    this.backdropState = this.backdropState === 'out' ? 'in' : 'out';
  }
  toggleShowForm(divName: string) {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  onMouseEnter() {
    //console.log('mouse enter');

    if (!this.dialogIsShown) {
      this.toggleBackdrop('overlay');
      this.toggleShowForm('myForm');
      this.dialogIsShown = true;
    }
  }

  @HostListener('mousleave', ['$event'])
  onMouseLeave(event: any) {
    //console.log('mouse leave');

    // window height and width
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    let posX = event.pageX;
    let posY = event.pageY;

    let outOfWindow = false;

    // console.log('coords x: ' + event.pageX + ' y: ' + event.pageY);

    if (posX < 0 || posX > screenWidth) {
      //console.log('out');
      outOfWindow = true;
    }

    if (posY < 0 || posY > screenHeight) {
      //console.log('out');
      outOfWindow = true;
    }

    if (this.dialogIsShown && outOfWindow && !this.fsShowFiles) {
      this.toggleBackdrop('overlay');
      this.toggleShowForm('myForm');
      this.dialogIsShown = false;
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocused() {
    //this.toggleBackdrop('overlay');
    //this.toggleShowForm('myForm');
  }
  @HostListener('window:blur', ['$event'])
  onBlur() {
    //this.toggleShowForm('myForm');
    //this.toggleBackdrop('overlay');
    //this.dialogIsShown = false;
  }

  /*
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    console.log('visibility change');
    this.toggleShowForm('myForm');
    this.toggleBackdrop('overlay');
  }
*/

  fullScreen() {
    this.myVideo = document.getElementById('video');
    this.videoContainer = document.getElementById('video-container-id');

    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.myVideo.style.height = '250px';
      this.myVideo.style.width = '100%';
    } else {
      this.videoContainer.requestFullscreen();
      this.videoTag.nativeElement.style.height = '100%';
      this.videoTag.nativeElement.style.width = '100%';
    }
  }

  /* video controls */
  togglePlay() {
    this.playing = !this.playing;
    this.playingIcon = this.playing ? 'play_arrow' : 'pause';

    this.myVideo = document.getElementById('video');
    if (this.myVideo.paused) this.myVideo.play();
    else this.myVideo.pause();
  }

  toggleMute() {
    this.mute = !this.mute;
    this.muteIcon = this.mute ? 'volume_mute' : 'volume_up';
  }

  toggleEye() {
    this.eye = !this.eye;
    this.eyeIcon = this.eye ? 'visibility' : 'visibility_off';
  }

  /*
  forgot - signup - login */

  goLogin() {
    this.activeTab = myTabs.tabLogin;
  }

  goForgot() {
    this.activeTab = myTabs.tabForgotPawd;
  }

  goSignup() {
    this.activeTab = myTabs.tabRegisterNew;
  }

  goRegisterSuccess() {
    this.activeTab = myTabs.tabRegisterSuccess;
  }

  /**
   *
   *
   */
  login() {
    this.isLoading = true;
    this.submitted = true;

    // only click once
    if (this.isLoginClicked) {
      return;
    }
    this.isLoginClicked = true;

    const login$ = this.authService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = true;
          this.isLoginClicked = false;
        })
      )
      .subscribe(
        (data) => {
          //
          // check for error
          //
          if (data.status === 401) {
            console.log('login error');

            // show error message
            if (data.error == 'PENDING_APPROVAL') {
              // pending approval (not yet approved)
              this._snackBar.open('Your registration is still looked at.', 'Close', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
                // panelClass: ['mat-toolbar', 'mat-primary'],
              });
            } else {
              // username or password wrong
              this._snackBar.open('Error: Username or password were wrong.', 'Close', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
                // panelClass: ['mat-toolbar', 'mat-primary'],
              });
            }
            return;
          } else {
            //
            // everything went OK
            //
            log.debug(`${data.name} successfully logged in`);

            const access_token = this.authQuery.access_token;
            const userid = this.authQuery.userid;
            const fid = this.authQuery.fid;
            const role = this.authQuery.role;

            this.journalService.log('Anmeldung erfolgreich.   --------------------------------------');
            this.cookieService.set('logged-on-today', '1', new Date()); // remember me funktion

            // create a cookie
            // not implemented yet
            // check if firststart is done
            if (data.firststart_done == 1 && data.isactivated == 1) {
              // if the user is an admin
              // go to the new dashboard

              // else goto the old system -belogin

              if (data.role == 1) {
                // admin
                this.router.navigate(['/home']);
              } else {
                // visitor or exhibitor
                // goto eventplus system
                const myform = document.createElement('form');
                myform.method = 'POST';
                myform.action = environment.eplusSystem + '/belogin.php';
                myform.style.display = 'none';
                myform.append('Content-Type', 'application/x-www-form-urlencoded');
                myform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');

                const inputAuth = document.createElement('input');
                inputAuth.type = 'hidden';
                inputAuth.name = 'Authorization';
                inputAuth.value = access_token;
                myform.appendChild(inputAuth);

                const inputUid = document.createElement('input');
                inputUid.type = 'hidden';
                inputUid.name = 'uid';
                inputUid.value = '' + userid;
                myform.appendChild(inputUid);

                const inputFid = document.createElement('input');
                inputFid.type = 'hidden';
                inputFid.name = 'fid';
                inputFid.value = '' + fid;
                myform.appendChild(inputFid);

                const inputRole = document.createElement('input');
                inputRole.type = 'hidden';
                inputRole.name = 'role';
                inputRole.value = '' + role;
                myform.appendChild(inputRole);

                document.body.appendChild(myform);
                myform.submit();
              }
            } else {
              // if (data.firststart_done == 0)
              //
              // weiche ---> je nachem
              // ob visitor | exhibitor
              //

              // goto firststart
              if (data.role == 2) {
                // exhibitor == 2
                this.activeTab = myTabs.tabFirststart;
                this.colLarge = 8;
                this.colXLarge = 8;

                // and now get the existing exhibitor data
                // so the exhibitor can change the already existing data

                // get the visitor with the given ID
                this.exhibitorService.getExhibitorById(fid).subscribe((exhibitor: ExhibitorSingle) => {
                  console.log('exhibitor from DB');
                  console.log(exhibitor);

                  // set the profile image
                  this._profile_image = exhibitor.data.profile_image;
                  this._logo = exhibitor.data.logo;
                  this._bild1 = exhibitor.data.bild1;
                  this._bild2 = exhibitor.data.bild2;
                  this._bild3 = exhibitor.data.bild3;

                  // const access_token = this.authQuery.access_token;

                  this.firstExhibitorForm.patchValue({
                    uid: userid,
                    fid: fid,
                    role: role,
                    //firststart_done: this.authQuery.firststart_done,

                    // judical things
                    //storno_accepted: exhibitor.data.storno_accepted == -1 ? true : false,
                    //dsgvo_accepted: exhibitor.data.dsgvo_accepted == -1 ? true : false,
                    agb_accepted: exhibitor.data.agb_accepted == -1 ? true : false,

                    // special wishes
                    special_wishes: exhibitor.data.special_wishes,
                    taxid_uid: exhibitor.data.uid,

                    // admin register new fields
                    company: exhibitor.data.organisation,
                    firstname: exhibitor.data.vorname,
                    lastname: exhibitor.data.name,
                    position: exhibitor.data.funktion,
                    //country: exhibitor.data.country,

                    //
                    adresse: exhibitor.data.adresse,
                    plz: exhibitor.data.plz,
                    stadt: exhibitor.data.stadt,
                    telefon: exhibitor.data.telefon,
                    email: exhibitor.data.email,
                    web: exhibitor.data.web,

                    category: exhibitor.data.category,
                    beschreibung: exhibitor.data.beschreibung,
                    info1: exhibitor.data.info1,

                    //Days
                    chkcity1: exhibitor.data.chkcity1 == 1 ? true : false,
                    chkcity2: exhibitor.data.chkcity2 == 1 ? true : false,
                  });

                  this.firstExhibitorForm.controls['regionsGroup'].patchValue({
                    auvergne: exhibitor.data.auvergne == -1 ? true : false,
                    bourgogne: exhibitor.data.bourgogne == -1 ? true : false,
                    bretagne: exhibitor.data.bretagne == -1 ? true : false,
                    centreloire: exhibitor.data.centreloire == -1 ? true : false,
                    corse: exhibitor.data.corse == -1 ? true : false,
                    champagne: exhibitor.data.champagne == -1 ? true : false,
                    nordcalais: exhibitor.data.nordcalais == -1 ? true : false,
                    normandie: exhibitor.data.normandie == -1 ? true : false,
                    aquitaine: exhibitor.data.aquitaine == -1 ? true : false,
                    languedoc: exhibitor.data.languedoc == -1 ? true : false,
                    outremer: exhibitor.data.outremer == -1 ? true : false,
                    paris: exhibitor.data.paris == -1 ? true : false,
                    paysloire: exhibitor.data.paysloire == -1 ? true : false,
                    picardie: exhibitor.data.picardie == -1 ? true : false,
                    paca: exhibitor.data.paca == -1 ? true : false,
                    multiregions: exhibitor.data.multiregions == -1 ? true : false,
                  });

                  this.firstExhibitorForm.controls['activitiesGroup'].patchValue({
                    agencereceptive: exhibitor.data.agencereceptive == -1 ? true : false,
                    centralereservation: exhibitor.data.centralereservation == -1 ? true : false,
                    destination: exhibitor.data.destination == -1 ? true : false,
                    excursion: exhibitor.data.excursion == -1 ? true : false,
                    groupement: exhibitor.data.groupement == -1 ? true : false,
                    locationfluviale: exhibitor.data.locationfluviale == -1 ? true : false,
                    musee: exhibitor.data.musee == -1 ? true : false,
                    transporteur: exhibitor.data.transporteur == -1 ? true : false,
                    autre: exhibitor.data.autre == -1 ? true : false,
                    offreski: exhibitor.data.offreski == -1 ? true : false,
                    ski_station: exhibitor.data.ski_station == -1 ? true : false,
                    ski_rental: exhibitor.data.ski_rental == -1 ? true : false,
                    residencetourisme: exhibitor.data.residencetourisme == -1 ? true : false,
                    restaurant: exhibitor.data.restaurant == -1 ? true : false,
                  });
                });
              } else {
                // we assume visitor (this is also if it is admin)

                let uid = this.authQuery.userid;
                let fid = this.authQuery.fid;
                let role = this.authQuery.role;
                let country = this.authQuery.country;
                let firststart_done = this.authQuery.firststart_done;

                this.router.navigate(['/firststart', { id: fid, _uid: uid, _fid: fid, _role: 3, _country: country, ia: 0 }]);
              }
            }

            /*
          this.router.navigate(
            [this.route.snapshot.queryParams['redirect'] || '/'],
            { replaceUrl: true }
          );
          */
          }
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  /**
   *
   *
   * @memberof PhutureLoginComponent
   */
  forgot() {
    /*
    var email = this.forgotForm.controls['email'].value;

    let data = {
      email: email
    };
    */
    this.submitted = true;
    this.isLoading = true;

    // only click once
    if (this.isForgetClicked) {
      return;
    }
    this.isForgetClicked = true;

    const changePwd$ = this.authService.forgotpwd(this.forgotForm.value);
    changePwd$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
          this.isForgetClicked = false;
        })
      )
      .subscribe(
        (data: any) => {
          console.log(data);

          // show done notification alert
          if (data.code == 200) {
            this._snackBar.open('Kennwortanfrage erfolgreich geschickt', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          } else {
            this._snackBar.open('Fehler aufgetreten.', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        },
        (error: any) => {
          // show error notification alert
          this._snackBar.open('Fehler aufgetreten', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      );
  }

  /**
   *
   *
   * @memberof PhutureLoginComponent
   */
  register() {
    console.log('check server > register new');
    this.submitted = true;
    this.isLoading = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // only click once
    if (this.isRegisterNewClicked) {
      return;
    }
    this.isRegisterNewClicked = true;

    const register$ = this.authService.register(this.registerForm.value);
    register$
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
          this.isRegisterNewClicked = false;
        })
      )
      .subscribe(
        (data) => {
          log.debug(`${data.name} successfully registered`);
          //this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/'], { replaceUrl: true });
          this.goRegisterSuccess();
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;

          this._snackBar.open('Error: E-Mail exists already', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      );
  }

  //
  //
  // -------------------------------------- firststart
  //

  selectedIndex: number = 0;

  setIndex(event) {
    this.selectedIndex = event.selectedIndex;
  }
  triggerClick() {
    //console.log(`Selected tab index: ${this.selectedIndex}`);
    if (this.selectedIndex == 4) {
      // wenn es sich um das file-drag-and-drop handelt
      // dann soll beim mouse-move der dialog stehen bleiben
      this.fsShowFiles = true;
    } else {
      this.fsShowFiles = false;
    }
  }

  showFirststart(event) {
    console.log('hidden click');
    if (event.ctrlKey) {
      //this.router.navigate(['/firststart']);
    }
  }

  /**
   *
   *
   */
  submitFirststart() {
    console.log('submit');
    this.submitted = true;

    // stop here if form is invalid
    if (this.firstExhibitorForm.invalid) {
      return;
      // we do not care if it is invalid
      // because we do a cache save
      //
    } else {
      // first-start can set to 1
      // and then save it agin
      //  (click)="submitFirststart()"
      this.firstExhibitorForm.controls['firststart_done'].setValue(1);
    }

    // display form values on success
    this.submitted = true;
    if (this.firstExhibitorForm.valid) {
      //console.table(this.firstExhibitorForm.value);
    }

    // set the form params
    const access_token = this.authQuery.access_token;
    const userid = this.authQuery.userid;
    const fid = this.authQuery.fid;
    const role = this.authQuery.role;

    //this.firstExhibitorForm.controls['access_token'].setValue(access_token);
    this.firstExhibitorForm.controls['uid'].setValue(userid);
    this.firstExhibitorForm.controls['fid'].setValue(fid);
    this.firstExhibitorForm.controls['role'].setValue(role);

    const firststart$ = this.authService.firststart(this.firstExhibitorForm.value);
    firststart$
      .pipe(
        finalize(() => {
          this.firstExhibitorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          if (data.status === 401) {
            console.log('login error');
            this._snackBar.open('Error: Something went wrong.', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
              // panelClass: ['mat-toolbar', 'mat-primary'],
            });
          } else {
            log.debug(`${data.name} successfully firststart done`);

            this.journalService.log('FirstStart erfolgreich abgeschlossen.   --------------------------------------');

            // we got the data back -> zwischenspeicher
            // load based on role

            // exhibitor == 2
            if (data.role == 2) {
              // exhibitor
              console.table(data.exhibitor);

              this.fsData = data.exhibitor;
            }

            // visitor == 3
            if (data.role == 3) {
              // visitor
              console.table(data.visitor);

              this.fsData = data.visitor;
            }

            // get firststart done
            let firststart_done = +data.firststart_done;

            // now route the user into the eplus system (sso )
            if (firststart_done == 1) {
              console.log('route to backend ---- sso');

              const myform = document.createElement('form');
              myform.method = 'POST';
              myform.action = environment.eplusSystem + '/belogin.php';
              myform.style.display = 'none';
              myform.append('Content-Type', 'application/x-www-form-urlencoded');
              myform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');

              const inputAuth = document.createElement('input');
              inputAuth.type = 'hidden';
              inputAuth.name = 'Authorization';
              inputAuth.value = access_token;
              myform.appendChild(inputAuth);

              const inputUid = document.createElement('input');
              inputUid.type = 'hidden';
              inputUid.name = 'uid';
              inputUid.value = '' + userid;
              myform.appendChild(inputUid);

              const inputFid = document.createElement('input');
              inputFid.type = 'hidden';
              inputFid.name = 'fid';
              inputFid.value = '' + fid;
              myform.appendChild(inputFid);

              const inputRole = document.createElement('input');
              inputRole.type = 'hidden';
              inputRole.name = 'role';
              inputRole.value = '' + role;
              myform.appendChild(inputRole);

              document.body.appendChild(myform);
              myform.submit();
            } else {
              console.log('do nothing');
            }
          }
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  //
  // -----------------------------------------------------
  //

  selectedVisitor = false;
  onSelectVisitor() {
    this.selectedVisitor = !this.selectedVisitor;
  }
  selectedExhibitor = false;
  onSelectExhibitor() {
    this.selectedExhibitor = !this.selectedExhibitor;
  }

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event);
  }
}

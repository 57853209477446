<mat-sidenav-container fullscreen (backdropClick)="sidebarOnClose()" (close)="sidebarOnClose()">
  <mat-sidenav #sidenav [opened]="openSidebar" position="end">
    <router-outlet></router-outlet>

    <button md-raised-button (click)="sidenav.toggle()">Toggle Sidenav</button>
  </mat-sidenav>

  <mat-toolbar>
    <span>Atout France - french-rendez-vous {{ _eventYear }}</span>
    <span class="my-spacer"></span>
    <button (click)="gotoLiveSystem()">Goto Live-System &gt;</button>
  </mat-toolbar>

  <!-- CARDS -->
  <div class="container" style="margin-top: 30px">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total Visitors</h6>
                <!-- Heading -->
                <span class="h2 mb-0"> {{ stats?.nbr_visitors }} </span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total Exhibitors</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ stats?.nbr_exhibitors }} </span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Number Appointments</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ stats?.nbr_appointments }} </span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- / .row -->

    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Register New</h6>
                <!-- Heading -->
                <span class="h2 mb-0"> {{ stats?.nbr_registered }} </span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Approved</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ stats?.nbr_approved }} </span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Firststart Done</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ stats?.nbr_fs_done }} </span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- / .row -->

    <!-- / .row -->
    <div class="row">
      <div class="col-12">
        <!-- Visitors -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Visitors</h4>
              </div>
            </div>
            <!-- / .row -->
          </div>

          <div class="mx-4">
            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white">
              <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
            </div>

            <mat-form-field appearance="standard">
              <mat-label>Search</mat-label>
              <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchValue" placeholder="" #input />
              <button
                mat-button
                *ngIf="searchValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchValue = ''; applyFilter($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <div class="flex gap-4">
              <mat-form-field class="flex-none" appearance="standard" *ngFor="let empfilter of empFilters">
                <mat-label>{{ empfilter.name }}</mat-label>
                <mat-select [(value)]="empfilter.defaultValue" (selectionChange)="applyEmpFilter($event, empfilter)">
                  <mat-option *ngFor="let op of empfilter.options" [value]="op">
                    {{ op }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="example-container">
            <table
              mat-table
              [dataSource]="dataSource"
              class="mat-elevation-z0"
              matSort
              matSortActive="t_visitorid"
              matSortDisableClear
              matSortDirection="desc"
              matTableExporter
              #exporter="matTableExporter">
              <!----------------------------------------------------------------- -->

              <!-- ID Column -->
              <ng-container matColumnDef="t_visitorid" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.t_visitorid }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">Total</td>
              </ng-container>

              <!-- zusatz1 Column -->
              <ng-container matColumnDef="zusatz1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Status</th>
                <td mat-cell *matCellDef="let row">
                  <div
                    *ngIf="row.zusatz1 == 'LAST_YEAR'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="[word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    LAST_YEAR
                  </div>

                  <div
                    *ngIf="row.zusatz1 == 'PENDING_APPROVAL'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="[word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    REGISTER
                  </div>

                  <div
                    *ngIf="row.zusatz1 == 'APPROVED_OK' && row.firststart_done == 0"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-approved [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    APPROVED
                  </div>

                  <div
                    *ngIf="row.zusatz1 == 'APPROVED_OK' && row.firststart_done == 1"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-active-fsdone [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ACTIVE
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- Profilbild Column -->
              <ng-container matColumnDef="profile_image">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-50">&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                  <phuture-iavatar src_image="{{ imageStorage }}{{ row.profile_image }}"></phuture-iavatar>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- Country Column -->
              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">PAYS</th>
                <td mat-cell *matCellDef="let row">
                  {{ getCountryName(row.country) }}
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">
                  {{ calculateTotal('country') }}
                </td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- Company Column -->
              <ng-container matColumnDef="company" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-150">SOCIETE</th>
                <td mat-cell *matCellDef="let row">{{ row.company }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- decharge_resp_civile Column -->
              <ng-container matColumnDef="decharge_resp_civile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>CIVILE DEVIS</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.decharge_resp_civile || row.decharge_resp_civile == 0">-</div>
                  <div *ngIf="row.decharge_resp_civile == -1 || row.decharge_resp_civile == 1">1</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">
                  {{ calculateTotal('decharge_resp_civile') }}
                </td>
              </ng-container>

              <!-- estimate Column -->
              <ng-container matColumnDef="estimate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>DEVIS</th>
                <td mat-cell *matCellDef="let row">{{ row.estimate }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">
                  {{ calculateTotal('estimate') }}
                </td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">TITLE</th>
                <td mat-cell *matCellDef="let row">{{ row.title }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- lastname Column -->
              <ng-container matColumnDef="lastname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">NOM</th>
                <td mat-cell *matCellDef="let row">{{ row.lastname }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- firstname Column -->
              <ng-container matColumnDef="firstname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">PRENOM</th>
                <td mat-cell *matCellDef="let row">{{ row.firstname }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">FONCTION</th>
                <td mat-cell *matCellDef="let row">{{ row.position }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- adress Column -->
              <ng-container matColumnDef="adress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-150">ADRESSE</th>
                <td mat-cell *matCellDef="let row">{{ row.adress }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- areacode Column -->
              <ng-container matColumnDef="areacode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">CP</th>
                <td mat-cell *matCellDef="let row">{{ row.areacode }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- city Column -->
              <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">VILLE</th>
                <td mat-cell *matCellDef="let row">{{ row.city }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- companyphone Column -->
              <ng-container matColumnDef="companyphone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-150">TEL</th>
                <td mat-cell *matCellDef="let row">{{ row.companyphone }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- emailincharge Column -->
              <ng-container matColumnDef="emailincharge">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">EMAIL In Charge</th>
                <td mat-cell *matCellDef="let row">{{ row.emailincharge }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- companymail Column -->
              <ng-container matColumnDef="companymail">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">Company EMAIL</th>
                <td mat-cell *matCellDef="let row">{{ row.companymail }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- icphone Column -->
              <ng-container matColumnDef="icphone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">Phone In Charge</th>
                <td mat-cell *matCellDef="let row">{{ row.icphone }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- website Column -->
              <ng-container matColumnDef="website">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">WEB</th>
                <td mat-cell *matCellDef="let row">{{ row.website }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- chkcity1 Column -->
              <ng-container matColumnDef="chkcity1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Day 1</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.chkcity1 || row.chkcity1 == 0">-</div>
                  <div *ngIf="row.chkcity1 == -1 || row.chkcity1 == 1">{{ row.chkcity1 }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('chkcity1') }}</td>
              </ng-container>

              <!-- chkcity2 Column -->
              <ng-container matColumnDef="chkcity2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Day 2</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.chkcity2 || row.chkcity2 == 0">-</div>
                  <div *ngIf="row.chkcity2 == -1 || row.chkcity2 == 1">{{ row.chkcity2 }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('chkcity2') }}</td>
              </ng-container>

              <!-- garage_day1 Column -->
              <ng-container matColumnDef="garage_day1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Garage Day 1</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.garage_day1 || row.garage_day1 == 0">-</div>
                  <div *ngIf="row.garage_day1 == -1 || row.garage_day1 == 1">{{ row.garage_day1 }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('garage_day1') }}</td>
              </ng-container>

              <!-- garage_day2 Column -->
              <ng-container matColumnDef="garage_day2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Garage Day 2</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.garage_day2 || row.garage_day2 == 0">-</div>
                  <div *ngIf="row.garage_day2 == -1 || row.garage_day2 == 1">{{ row.garage_day2 }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('garage_day2') }}</td>
              </ng-container>

              <!-- lunch_day1 Column -->
              <ng-container matColumnDef="lunch_day1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>DEJ Day 1</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.lunch_day1 || row.lunch_day1 == 0">-</div>
                  <div *ngIf="row.lunch_day1 == -1 || row.lunch_day1 == 1">{{ row.lunch_day1 }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('lunch_day1') }}</td>
              </ng-container>

              <!-- lunch_day2 Column -->
              <ng-container matColumnDef="lunch_day2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>DEJ Day 2</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.lunch_day2 || row.lunch_day2 == 0">-</div>
                  <div *ngIf="row.lunch_day2 == -1 || row.lunch_day2 == 1">{{ row.lunch_day2 }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('lunch_day2') }}</td>
              </ng-container>

              <!-- attendance_event Column -->
              <ng-container matColumnDef="attendance_event">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>SOIREE</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.attendance_event || row.attendance_event == 0">-</div>
                  <div *ngIf="row.attendance_event == -1 || row.attendance_event == 1">{{ row.attendance_event }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('attendance_event') }}</td>
              </ng-container>

              <!-- hotel_stay_night Column -->
              <ng-container matColumnDef="hotel_stay_night">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>NUITEE</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.hotel_stay_night || row.hotel_stay_night == 0">-</div>
                  <div *ngIf="row.hotel_stay_night == -1 || row.hotel_stay_night == 1">{{ row.hotel_stay_night }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('hotel_stay_night') }}</td>
              </ng-container>

              <!-- transfer_arrival Column -->
              <ng-container matColumnDef="transfer_arrival">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>TRANSFERT ALLER</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.transfer_arrival || row.transfer_arrival == 0">-</div>
                  <div *ngIf="row.transfer_arrival == -1 || row.transfer_arrival == 1">{{ row.transfer_arrival }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('transfer_arrival') }}</td>
              </ng-container>

              <!-- transfer_return Column -->
              <ng-container matColumnDef="transfer_return">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>TRANSFERT RETOUR</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.transfer_return || row.transfer_return == 0">-</div>
                  <div *ngIf="row.transfer_return == -1 || row.transfer_return == 1">{{ row.transfer_return }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('transfer_return') }}</td>
              </ng-container>

              <!-- data1 Column -->
              <ng-container matColumnDef="data1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ALLER</th>
                <td mat-cell *matCellDef="let row">{{ row.data1 }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- flight_booked Column -->
              <ng-container matColumnDef="flight_booked">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>FLIGHT BOOKED</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="!row.flight_booked || row.flight_booked == 0">-</div>
                  <div *ngIf="row.flight_booked == -1 || row.flight_booked == 1">{{ row.flight_booked }}</div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">{{ calculateTotal('flight_booked') }}</td>
              </ng-container>

              <!-- data2 Column -->
              <ng-container matColumnDef="data2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>TYPE</th>
                <td mat-cell *matCellDef="let row">{{ row.data2 }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- special_wishes Column -->
              <ng-container matColumnDef="special_wishes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Remarque</th>
                <td mat-cell *matCellDef="let row">{{ row.special_wishes }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- additional Columns -->
              <ng-container [matColumnDef]="column" *ngFor="let column of additionalColumns; let i = index">
                <th mat-header-cell *matHeaderCellDef>{{ beautyNamesColumns[i] }}</th>
                <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <!-- actions button bar Column -->

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                  <button
                    type="button"
                    matRipple
                    [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
                    class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
                    [style]="{ 'margin-left': '.5em' }"
                    (click)="showPageDetail(row.t_visitorid)"
                    [queryParams]="{ debug: true }"
                    fragment="education">
                    Edit
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let emprow; columns: displayedColumns" (click)="showPageDetail(emprow.t_visitorid)"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
          </div>
          <mat-paginator
            #paginator
            [length]="totalItems"
            [pageSize]="initPageSize"
            [pageSizeOptions]="pageSizes"
            showFirstLastButtons></mat-paginator>
        </div>
        <div>
          <button
            type="button"
            matRipple
            [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
            class="bg-[#eceff1] hover:bg-blue-700 text-white py-2 px-4"
            (click)="showAdditionalColumns()">
            Add All Columns
          </button>
          &nbsp;

          <button
            type="button"
            matRipple
            [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
            class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
            (click)="exporter.exportTable('xlsx')">
            Export Excel
          </button>
          &nbsp;
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
    <footer class="p-4 md:px-6 md:py-8 dark:bg-gray-900">
      <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400"
        >&copy; {{ _eventYear }} Atout France - All Rights Reserved.</span
      >
    </footer>
  </div>
</mat-sidenav-container>
